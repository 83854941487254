
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "customer-details",
  props: {
    link: Boolean,
    tickets: Boolean,
    references: Boolean,
    sites: Boolean,
  },
  setup(props) {

    const state = reactive({
      myList: [] as any,
      lSites: [] as any,
      loaderEnabeled: true,
      link: props.link,
      tickets: props.tickets,
      references: props.references,
      sites: props.sites,
      totalElements: 0,
    });
    const { t } = useI18n()

    const Plur = (item) => {
      if (item>0) return t('s');
    }

    onMounted(async () => {
        [state.tickets, state.references, state.sites].forEach(el => {
          state.totalElements = el ? state.totalElements + 1 : state.totalElements
        })
        let myList = await mAxiosApi.prototype.getAxios((!props.tickets ? '/getOverviewDeploy/stock' : '/getOverviewDeploy'))
        state.myList = myList.results;
        state.lSites = myList.lsites;
        state.loaderEnabeled = false;
    });

    return {
      state,
      Plur,
      model: {}
    };
  },
});
