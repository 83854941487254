
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import { useI18n } from "vue-i18n";
import StockRefs from "./stockRefs.vue";

import mAxiosApi from "@/api";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Liste des commandes en cours",
  components: {
    Datatable,
    SearchAndFilter,
    StockRefs,
  },
  setup() {
    //moment.default.locale("fr");
    const router = useRouter()
    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      sourcePDF: "",
      mObj: {} as any,
      mFact: "",
      initialMyList: [],
      loadingDatatable: 0,
      search: "",
      refMask: 0,
      searchDefault: router.currentRoute.value.params.code ? router.currentRoute.value.params.code + "" : "",
    });
    const { t } = useI18n()
    const activeName = "first"

    const tableHeader = ref([
      {
        name: "Equipement",
        key: "theReference",
        sortable: true,
      },
      {
        name: "N° PO",
        key: "toc_code",
        sortable: true,
      },
      {
        name: "Qté Dispo / Réappro",
        key: "qte",
        sortable: true,
      },
      {
        name: "Echéance stock",
        key: "date_liv",
        sortable: true,
      },
      {
        name: "Historique",
        key: "histo",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      // {
      //   name: "Sites",
      //   key: "site",
      //   label: "site",
      // },
      {
        name: "Réapprovisionnement",
        key: "status_reappro",
        label: "status_reappro",
      },
      {
        name: "Référence",
        key: "loc_reference",
        label: "loc_reference"
      },
      {
        name: "Commande",
        key: "cmdFiltre",
        label: "cmdFiltre",
      },
      {
        name: "Disponibilité",
        key: "status_qte",
        label: "status_qte",
        default : ["Disponible"],
      },
    ]);

    const ExcelFormat = ref({
      "Constructeur" : "soc_nom_societe",
      "Référence": "loc_reference",
      "Quantité": "qte",  
      "N° PO": "toc_code_po",
      "Disponible le": "date_liv",
      Site: "site",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const formatMoney = (n) => {
      if (!n) n = 0;
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const returnUrlOffre = (data) => {
      return `/stillboard/offres/${data}`;
    };

    const returnUrlCommande = (data) => {
      return `/commande/${data}/livraisons`;
    };

    const formatHoverDateD = (data) => {
      if (!data.date_old_ldc) {
        return `${t("La date de livraison n'a connue aucune variation depuis le")} ${formatDate(
          data.toc_date_creation
        )} `;
      }
      return `${formatDateAgo(
        data.fraicheur
      )}, ${t("la date de livraison est passée du")} ${formatDate(
        data.date_old_ldc
      )} ${t("au")} ${formatDate(data.date_ldc)}`;
    };

    const affDetail = async () => {
      state.drawer = true;
    };

    const checkEcheance = (date) => {
      date = new Date(date)
      return new Date >= date ? "text-danger" : "text-dark"
    }

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Outils de déploiement", []);

      let myList = await mAxiosApi.prototype.getAxios("/getstock").catch(() => {return false});
      let getstockProduct = await mAxiosApi.prototype.getAxios("/getstockProduct").catch(() => {return false});
      let getstockReappro = await mAxiosApi.prototype.getAxios("/getstockReappro").catch(() => {return false});
      let getstockEcheance = await mAxiosApi.prototype.getAxios("/getstockEcheance").catch(() => {return false});
      
      let myListServiceFinal = [] as any;
      state.refMask = 0;
      myList.records.forEach((el) => {

        el.theReference = el.loc_reference;
        if (el.liv_ref_secondaire) el.theReference = el.liv_ref_secondaire;
        

        el.qte_exped = 0;
        // el.qte_max = parseInt(el.qte) // + parseInt(el.reappro)
        // el.qte = parseInt(el.qte);      
        el.toc_code = JSON.parse(el.toc_code);
        el.toc_code_all = JSON.parse(el.toc_code_all);

        if(el.toc_code_all.filter(el => el.toc_x_invisible_stock== 1).length > 0 ) {
          el.visible = 0;
          state.refMask++;
        } else {
          el.visible = 1;
        }
        const oldEl = {...el};
        const arrayToc = [...el.toc_code];
        const arrayTocAll = [...el.toc_code_all];
       
        arrayToc.forEach((el2) => {
          el.toc_code = [el2];
          el.toc_code_all = arrayTocAll.filter(al => al.refCmd == el2.numcmd );

          if (!el.liv_ref_secondaire) {
            el.qte_max = getstockProduct.records.filter(aa => aa.toc_code == el2.numcmd && aa.loc_reference == el.loc_reference && !aa.liv_ref_secondaire)[0].en_stock;
          } else {
            el.qte_max = getstockProduct.records.filter(aa => aa.toc_code == el2.numcmd && aa.loc_reference == el.loc_reference && aa.liv_ref_secondaire == el.liv_ref_secondaire)[0].en_stock;
          }
          el.qte = parseInt(el.qte_max);  
          el.numcmd = el2.numcmd;

          el.reappro = 0;
          el.date_reappro = "";
          el.status_reappro = "Sans réapprovisionnement";
      
          if (el.qte > 0 || el.reappro > 0) {
            el.status_qte = 'Disponible';
          } else {
            el.status_qte = 'Indisponible';
          }
          if(el.toc_code_all.filter(el => el.toc_x_invisible_stock== 1).length > 0 ) {
            el.status_qte = "Référence masquée";
          }
          
          el.cmdFiltre = el.toc_code[0].code;

          el.list_ticketsjson = JSON.parse(el.list_tickets)
          el.list_ticketsjson = el.list_ticketsjson.filter(element => {
            const searchStr =  ","+element.toc_seq+",";
            return searchStr.indexOf(el.toc_code[0].toc_seq);
          })

          let baseTotal = 0
          el.list_ticketsjson.forEach(element => {
            if (element.toc_seq == el.toc_code[0].toc_seq) {
              baseTotal = baseTotal + element.total
            }
          });
          el.total_qte = baseTotal + el.qte

          const date_ech = getstockEcheance.records.filter(ee => {
           return ee.loc_reference == el.loc_reference && ee.toc_seq == el.toc_code[0].toc_seq
          })

          el.date_liv = date_ech.length > 0 ?  date_ech[0].date_echeance : "";

          myListServiceFinal.push({...el});
        })
      })

      getstockReappro.records.forEach((el) => {

        const DateLiv = getstockProduct.records.filter(aa => aa.toc_seq == el.toc_seq && aa.loc_reference == el.loc_reference && aa.liv_ref_secondaire == el.liv_ref_secondaire)[0].liv_date_liv;

        const arrayFi = myListServiceFinal.findIndex(aa => aa.loc_reference == el.loc_reference && aa.liv_ref_secondaire == el.liv_ref_secondaire && aa.toc_code[0].toc_seq == el.toc_seq);
        if (arrayFi > -1 ) {
          myListServiceFinal[arrayFi].reappro = el.total;
          if (myListServiceFinal[arrayFi].reappro > 0) {
            myListServiceFinal[arrayFi].status_qte = 'Disponible';
            myListServiceFinal[arrayFi].status_reappro = "Réapprovisionnement en cours";
            myListServiceFinal[arrayFi].date_reappro = DateLiv; //el.date_reappro;
            if (!myListServiceFinal[arrayFi].qte) myListServiceFinal[arrayFi].date_liv = "";
          }
        }else {
          myListServiceFinal.push(
            {
              'qte' : 0,
              'status_qte' : 'Disponible',
              'status_reappro' : 'Réapprovisionnement en cours',
              'date_reappro' : DateLiv, //el.date_reappro,
              'loc_reference' : el.loc_reference,
              'liv_ref_secondaire' : el.liv_ref_secondaire,
              'reappro' : el.total,
              'loc_designation' : el.loc_designation,
              'soc_nom_societe' : el.soc_nom_societe,
              'toc_code' : [{'toc_seq' : el.toc_seq,  'numcmd' : el.toc_code, 'code' : el.toc_num_commande_client, }],
              'list_ticketsjson' : {},
              'theReference' : el.liv_ref_secondaire ? el.liv_ref_secondaire : el.loc_reference,
            }          
          );
        }

      })



      myListServiceFinal = myListServiceFinal.sort((a, b) => (a.theReference > b.theReference) ? 1 : -1);
      state.initialMyList = myListServiceFinal;
      state.myList = myListServiceFinal;
      state.loaderEnabled = false;

    });

    const openHisto = async (data) => {
      state.mObj = data;
      state.drawer = true;
    };

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      formatMoney,
      affDetail,
      formatHoverDateD,
      returnUrlOffre,
      returnUrlCommande,
      tableFilters,
      ExcelFormat,
      openHisto,
      checkEcheance,
      activeName,
    };
  },
});
