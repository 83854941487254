<template>
  <div
    style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
    v-if="state.loaderEnabled"
  >
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>
  <transition name="fade">
    <div v-if="!state.loaderEnabled">
      <div class="card">
        <div class="card-header border-0 pt-6 pb-6">
            <SearchAndFilter
              :listInitial="state.initialMyList"
              :filters="[]"
              :key="state.initialMyList"
              :searchDefault="state.searchDefault"
              @returnSearch="state.myList = $event"
              @returnLoadingDatatable="state.loadingDatatable = $event"
              ExcelFilename="Stillnetwork_Stock"
              :ExcelFormat="ExcelFormat"
            ></SearchAndFilter>
          <!-- <SearchAndFilter
            :listInitial="state.initialMyList"
            :filters="tableFilters"
            @returnSearch="state.myList = $event"
            @returnLoadingDatatable="state.loadingDatatable = $event"
            ExcelFilename="Stillnetwork_Stock"
            :ExcelFormat="ExcelFormat"
          ></SearchAndFilter> -->
        </div>
      </div>

      <div class="card mt-5">
        <div class="card-body pt-0">
          <el-container>
            <el-aside width="280px" style="overflow: hidden;">

            <el-tabs class="tabsPresta pt-9 mb-10">
              <el-tab-pane disabled :label="$t('Synthèse et filtres')" ></el-tab-pane>
            </el-tabs>

            <div style="min-height: 120px;">
              <StockRefs references/>
            </div>

            <div class="card-title">
              <h4 class="fw-bolder mb-0 mt-6">{{$t("Filtrer la liste :")}}</h4>
            </div>

            <div class="card-header border-0 p-0">
              <SearchAndFilter
                :listInitial="state.initialMyList"
                :filters="tableFilters"
                :searchDefault="state.searchDefault"
                @returnSearch="state.myList = $event"
                @returnLoadingDatatable="state.loadingDatatable = $event"
                widthDecalage="0"
                searchBar="0"
                classGroupSelect="align-items-center"
                classSelect="mx-0 w-275px text-primary me-3 m-2 mt-2"
              ></SearchAndFilter>
            </div>
          </el-aside>

            <el-main style="padding-top: 10px; padding-right: 0px;">
              <el-tabs v-model="activeName" class="border-0 tabsPresta pt-6" style="padding: 0 2.25rem;padding-right: 10px;">
                <el-tab-pane :label="$t('Liste des équipements en stock')" class="p-0" name="first" style="margin: 15px">
                  <div
                    v-if="state.myList.length == 0"
                    class="
                      alert
                      bg-light-primary
                      alert-primary
                      d-flex
                      align-items-center
                      p-5
                      mb-10
                    "
                  >
                    <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                      <inline-svg src="media/icons/duotune/general/gen048.svg" />
                    </span>
                    <div class="d-flex flex-column">
                      <h4 class="mb-1 text-dark">{{$t("Aucun équipement de stock")}}</h4>
                      <span
                        >{{$t("Il n'existe aucun équipement de stock à afficher correspondant à vos critères de recherche.")}}</span
                      >
                    </div>
                  </div>
                  <Datatable
                    v-if="state.myList && state.myList.length > 0"
                    :table-data="state.myList"
                    :table-header="tableHeader"
                    :enable-items-per-page-dropdown="true"
                    :empty-table-text="$t('Aucune commande à afficher.')"
                    :key="state.loadingDatatable"
                  >
                    <template v-slot:cell-theReference="{ row: data }">
                      <div class="text-gray-800"  style="margin-left: 5px; min-width: 500px;">
                        <img :src="`data:image/jpg;base64,${data.soc_logo}`"  v-if="data.soc_logo" alt="image" style="width: auto; max-height: 40px; max-width:75px; background-color: #ececec; float: left;margin-right:10px;" />
                        <div v-else class="badge badge-white" style="max-height: 40px; max-width:75px; background-color: #ececec; float: left;margin-right:10px;">{{ data.soc_nom_societe }}</div>
                        <div class="text-gray-400">
                          <span class="w-150px fs-6 text-dark">{{ data.theReference }}</span>
                        </div>
                        <span class="fs-7 text-gray-500">{{ data.loc_designation }}</span>
                        <br>
                        <div v-html="data.site" class="badge badge-secondary fs-9 text-gray-700" style="margin-left: 65px;"></div>
                      </div>
                    </template>
                    <template v-slot:cell-qte="{ row: data }">
                      <span class="badge badge-white w-200px text-gray-900">
                        <span v-if="data.qte>0" class="text-dark fs-4">{{ data.qte }}</span>
                        <span v-else-if="data.reappro>0" class="text-gray-400 fs-7">Réappro. en cours</span>
                        <span v-else class="text-danger">{{$t("Indisponible")}}</span>
                      </span>
                      <br>
                      <span v-if="data.reappro>0" class="badge badge-white w-200px fs-6 text-gray-900 mt-1">
                        + {{ data.reappro }} 
                        <span v-if="data.date_reappro" class="text-gray-400  fs-7">{{$t("dés le")}} {{ formatDate(data.date_reappro) }}</span>
                        <span v-else class="text-gray-400  fs-7">({{$t("date en attente")}})</span>
                      </span>
                    </template>
                    <template v-slot:cell-date_liv="{ row: data }">
                      <span v-if="data.date_liv" :class="checkEcheance(data.date_liv)">{{ formatDate(data.date_liv) }}</span>
                    </template>
                    <template v-slot:cell-toc_code="{ row: data }">
                      <template v-for="(item, index) in data.toc_code" :key="index">
                        <el-popover placement="right" :width="320" trigger="hover" :content="`${$t('Commande Stillnetwork :')} ${item.numcmd}`" :show-arrow="true">
                          <template #reference>
                            <router-link
                              :to="`/commande/${ item.toc_seq }/livraisons`"
                              class="btn btn-white text-primary btn-sm mx-1"
                            >
                              {{ item.code }}
                            </router-link>
                          </template>
                        </el-popover>     
                      </template>         
                    </template>
                    <template v-slot:cell-histo="{ row: data }">
                      <a class="btn btn-sm btn-active-primary mt-2 mb-2 btn-bg-white btn-text-dark" data-bs-toggle="modal" data-bs-target="#kt_modal_2" v-if="data.list_ticketsjson.length >0 " @click="openHisto(data)">
                        <span class="svg-icon svg-icon-1">
                          <inline-svg src="media/icons/duotune/maps/map009.svg" />
                          {{$t("Historique")}}
                        </span>
                      </a>
                    </template>
                  </Datatable>
                </el-tab-pane>
              </el-tabs>
            </el-main>
          </el-container>
        </div>
      </div>
    </div>
  </transition>
  <el-drawer
    v-model="state.drawer"
    title=""
    :with-header="false"
    size="450px"
    modal-class="modal-offre"
  >
    <div class="card shadow-none" style="width: 100%; height: 100%">
      <!--begin::Header-->
      <div class="card-header" id="kt_activities_header">
        <h3 class="card-title fw-bolder text-dark">{{$t("Détails du stock")}}</h3>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-icon btn-active-light-primary me-n5"
            @click="state.drawer = false"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </button>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body position-relative" id="kt_activities_body" style="overflow-y: scroll; height: calc(100vh - 70px);">

        <div class="fs-5 w-100% text-center text-white mt-3 mb-6 p-4" style='background:#003553 !important;  border-radius: 5px'>
          {{$t("Concerne votre équipement")}} {{ state.mObj.soc_nom_societe }}
        </div>

        <div class="fs-5 mx-3"><strong>{{ state.mObj.loc_reference }}</strong><br /></div>
        <div class="fs-7 mx-3">{{ state.mObj.loc_designation }}</div>
        <br>
        <div class="fs-6 mb-2 text-primary mx-3" v-if="state.mObj.site "><b>{{ state.mObj.site }} :</b></div>
        <div class="fs-6 mb-2 text-primary mx-3" v-else>{{$t("Stock en cours :")}}</div>
        <div class="fs-5 text-gray-900 mx-3">
          <span v-if="state.mObj.qte>0" class="text-dark">{{$t("Quantité disponible :")}} <b>{{ state.mObj.qte }}</b></span>
          <span v-else class="text-danger">{{$t("Aucune disponibilité pour le moment")}}</span>
        </div>
        <div v-if="state.mObj.reappro>0" class="fs-5 text-gray-900 mt-1 mx-3">
          {{$t("Réapprovisionnement :")}} <b>{{ state.mObj.reappro }}</b>
          <span v-if="state.mObj.date_reappro">&nbsp;{{$t("dés le")}} {{ formatDate(state.mObj.date_reappro) }}</span>
          <span v-else class="text-gray-600">({{$t("date en attente")}})</span>
        </div>
        <br>

        <div class="fs-5 w-100% text-center text-white mt-8 mb-4 p-4 " style='background:#003553 !important; border-radius: 5px'>
          {{$t("Historique du stock")}}
        </div>
        
        <span class="fs-5 text-gray-900 mx-3">
        {{$t("Initialisation du stock :")}} <span class="text-primary fs-5"><b>{{ state.mObj.total_qte }}</b></span>
        </span>
       

        <div class="py-5">
          <div class="d-flex flex-column w-100%" style='margin-right: 20px'>
            
            <li
              class="d-flex align-items-center py-2 fs-6 w-100%"
              v-for="item in state.mObj.list_ticketsjson" :key="item.tic_seq"
            >
            
              <template v-if="item.toc_seq == state.mObj.toc_code[0].toc_seq"> 
              <router-link
                :to="`/stilldeploy/ticket/${ item.tic_seq }`"
                class="text-dark"
              >

                <div class="mx-3 pb-4" style="width: 100% !important; border-bottom: 1px solid #0035532e">
                  {{$t("Ticket")}} <b>#{{ item.tic_seq }}</b> {{$t("du")}} {{ formatDate(item.tic_d_creation) }} {{$t("par")}} {{ item.con_prenom }}. {{ item.con_nom }}<br>
                  <i v-if="item.tic_c_label">{{ item.tic_c_label }}<br></i>
                  <span>{{ item.site_adr1 }}<br>{{ item.site_cp }} {{ item.site_ville }}<br></span>
                  {{$t("Sortie du stock :")}} <span class="text-primary fs-5"><b>{{ item.total }}</b></span>                
                </div>

              </router-link>
              </template>


            </li>
          </div>
        </div>
      </div>
      <!--end::Body-->
      <!--end::Footer-->
    </div>
  </el-drawer>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import { useI18n } from "vue-i18n";
import StockRefs from "./stockRefs.vue";

import mAxiosApi from "@/api";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Liste des commandes en cours",
  components: {
    Datatable,
    SearchAndFilter,
    StockRefs,
  },
  setup() {
    //moment.default.locale("fr");
    const router = useRouter()
    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      sourcePDF: "",
      mObj: {} as any,
      mFact: "",
      initialMyList: [],
      loadingDatatable: 0,
      search: "",
      refMask: 0,
      searchDefault: router.currentRoute.value.params.code ? router.currentRoute.value.params.code + "" : "",
    });
    const { t } = useI18n()
    const activeName = "first"

    const tableHeader = ref([
      {
        name: "Equipement",
        key: "theReference",
        sortable: true,
      },
      {
        name: "N° PO",
        key: "toc_code",
        sortable: true,
      },
      {
        name: "Qté Dispo / Réappro",
        key: "qte",
        sortable: true,
      },
      {
        name: "Echéance stock",
        key: "date_liv",
        sortable: true,
      },
      {
        name: "Historique",
        key: "histo",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      // {
      //   name: "Sites",
      //   key: "site",
      //   label: "site",
      // },
      {
        name: "Réapprovisionnement",
        key: "status_reappro",
        label: "status_reappro",
      },
      {
        name: "Référence",
        key: "loc_reference",
        label: "loc_reference"
      },
      {
        name: "Commande",
        key: "cmdFiltre",
        label: "cmdFiltre",
      },
      {
        name: "Disponibilité",
        key: "status_qte",
        label: "status_qte",
        default : ["Disponible"],
      },
    ]);

    const ExcelFormat = ref({
      "Constructeur" : "soc_nom_societe",
      "Référence": "loc_reference",
      "Quantité": "qte",  
      "N° PO": "toc_code_po",
      "Disponible le": "date_liv",
      Site: "site",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const formatMoney = (n) => {
      if (!n) n = 0;
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const returnUrlOffre = (data) => {
      return `/stillboard/offres/${data}`;
    };

    const returnUrlCommande = (data) => {
      return `/commande/${data}/livraisons`;
    };

    const formatHoverDateD = (data) => {
      if (!data.date_old_ldc) {
        return `${t("La date de livraison n'a connue aucune variation depuis le")} ${formatDate(
          data.toc_date_creation
        )} `;
      }
      return `${formatDateAgo(
        data.fraicheur
      )}, ${t("la date de livraison est passée du")} ${formatDate(
        data.date_old_ldc
      )} ${t("au")} ${formatDate(data.date_ldc)}`;
    };

    const affDetail = async () => {
      state.drawer = true;
    };

    const checkEcheance = (date) => {
      date = new Date(date)
      return new Date >= date ? "text-danger" : "text-dark"
    }

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Outils de déploiement", []);

      let myList = await mAxiosApi.prototype.getAxios("/getstock").catch(() => {return false});
      let getstockProduct = await mAxiosApi.prototype.getAxios("/getstockProduct").catch(() => {return false});
      let getstockReappro = await mAxiosApi.prototype.getAxios("/getstockReappro").catch(() => {return false});
      let getstockEcheance = await mAxiosApi.prototype.getAxios("/getstockEcheance").catch(() => {return false});
      
      let myListServiceFinal = [] as any;
      state.refMask = 0;
      myList.records.forEach((el) => {

        el.theReference = el.loc_reference;
        if (el.liv_ref_secondaire) el.theReference = el.liv_ref_secondaire;
        

        el.qte_exped = 0;
        // el.qte_max = parseInt(el.qte) // + parseInt(el.reappro)
        // el.qte = parseInt(el.qte);      
        el.toc_code = JSON.parse(el.toc_code);
        el.toc_code_all = JSON.parse(el.toc_code_all);

        if(el.toc_code_all.filter(el => el.toc_x_invisible_stock== 1).length > 0 ) {
          el.visible = 0;
          state.refMask++;
        } else {
          el.visible = 1;
        }
        const oldEl = {...el};
        const arrayToc = [...el.toc_code];
        const arrayTocAll = [...el.toc_code_all];
       
        arrayToc.forEach((el2) => {
          el.toc_code = [el2];
          el.toc_code_all = arrayTocAll.filter(al => al.refCmd == el2.numcmd );

          if (!el.liv_ref_secondaire) {
            el.qte_max = getstockProduct.records.filter(aa => aa.toc_code == el2.numcmd && aa.loc_reference == el.loc_reference && !aa.liv_ref_secondaire)[0].en_stock;
          } else {
            el.qte_max = getstockProduct.records.filter(aa => aa.toc_code == el2.numcmd && aa.loc_reference == el.loc_reference && aa.liv_ref_secondaire == el.liv_ref_secondaire)[0].en_stock;
          }
          el.qte = parseInt(el.qte_max);  
          el.numcmd = el2.numcmd;

          el.reappro = 0;
          el.date_reappro = "";
          el.status_reappro = "Sans réapprovisionnement";
      
          if (el.qte > 0 || el.reappro > 0) {
            el.status_qte = 'Disponible';
          } else {
            el.status_qte = 'Indisponible';
          }
          if(el.toc_code_all.filter(el => el.toc_x_invisible_stock== 1).length > 0 ) {
            el.status_qte = "Référence masquée";
          }
          
          el.cmdFiltre = el.toc_code[0].code;

          el.list_ticketsjson = JSON.parse(el.list_tickets)
          el.list_ticketsjson = el.list_ticketsjson.filter(element => {
            const searchStr =  ","+element.toc_seq+",";
            return searchStr.indexOf(el.toc_code[0].toc_seq);
          })

          let baseTotal = 0
          el.list_ticketsjson.forEach(element => {
            if (element.toc_seq == el.toc_code[0].toc_seq) {
              baseTotal = baseTotal + element.total
            }
          });
          el.total_qte = baseTotal + el.qte

          const date_ech = getstockEcheance.records.filter(ee => {
           return ee.loc_reference == el.loc_reference && ee.toc_seq == el.toc_code[0].toc_seq
          })

          el.date_liv = date_ech.length > 0 ?  date_ech[0].date_echeance : "";

          myListServiceFinal.push({...el});
        })
      })

      getstockReappro.records.forEach((el) => {

        const DateLiv = getstockProduct.records.filter(aa => aa.toc_seq == el.toc_seq && aa.loc_reference == el.loc_reference && aa.liv_ref_secondaire == el.liv_ref_secondaire)[0].liv_date_liv;

        const arrayFi = myListServiceFinal.findIndex(aa => aa.loc_reference == el.loc_reference && aa.liv_ref_secondaire == el.liv_ref_secondaire && aa.toc_code[0].toc_seq == el.toc_seq);
        if (arrayFi > -1 ) {
          myListServiceFinal[arrayFi].reappro = el.total;
          if (myListServiceFinal[arrayFi].reappro > 0) {
            myListServiceFinal[arrayFi].status_qte = 'Disponible';
            myListServiceFinal[arrayFi].status_reappro = "Réapprovisionnement en cours";
            myListServiceFinal[arrayFi].date_reappro = DateLiv; //el.date_reappro;
            if (!myListServiceFinal[arrayFi].qte) myListServiceFinal[arrayFi].date_liv = "";
          }
        }else {
          myListServiceFinal.push(
            {
              'qte' : 0,
              'status_qte' : 'Disponible',
              'status_reappro' : 'Réapprovisionnement en cours',
              'date_reappro' : DateLiv, //el.date_reappro,
              'loc_reference' : el.loc_reference,
              'liv_ref_secondaire' : el.liv_ref_secondaire,
              'reappro' : el.total,
              'loc_designation' : el.loc_designation,
              'soc_nom_societe' : el.soc_nom_societe,
              'toc_code' : [{'toc_seq' : el.toc_seq,  'numcmd' : el.toc_code, 'code' : el.toc_num_commande_client, }],
              'list_ticketsjson' : {},
              'theReference' : el.liv_ref_secondaire ? el.liv_ref_secondaire : el.loc_reference,
            }          
          );
        }

      })



      myListServiceFinal = myListServiceFinal.sort((a, b) => (a.theReference > b.theReference) ? 1 : -1);
      state.initialMyList = myListServiceFinal;
      state.myList = myListServiceFinal;
      state.loaderEnabled = false;

    });

    const openHisto = async (data) => {
      state.mObj = data;
      state.drawer = true;
    };

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      formatMoney,
      affDetail,
      formatHoverDateD,
      returnUrlOffre,
      returnUrlCommande,
      tableFilters,
      ExcelFormat,
      openHisto,
      checkEcheance,
      activeName,
    };
  },
});
</script>
